@import url(//netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  font-family: 'Montserrat', sans-serif;
  text-align: justify;
  height: 100vh;
}
h5 { font-size: 1.2rem; }
#page-wrap {
  text-align: justify;
  padding: 20px;
  /* Prevent sidebar from showing a scrollbar on page */
  overflow: auto;
}
.card{border: none;}
.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 20vmin;
  pointer-events: none;
}

body {font-family: 'Montserrat', sans-serif !important; background-color: #282c34; font-size: 1rem; color: #666;}

.App-header {
  background-color: #282c34;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #622D7A;
}
a {color:white;}
/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
.flex{
  display: flex;
  flex-direction: row;
}
.flex-item{
  flex-grow: 1;
}
.emp-profile{
    padding: 3%;
    margin-top: 3%;
    margin-bottom: 3%;
    border-radius: 0.5rem;
    background: #fff;
}
.profile-img{
    text-align: center;
}
.profile-img img{
    width: 70%;
    height: 100%;
}
.profile-img .file {
    position: relative;
    overflow: hidden;
    margin-top: -20%;
    width: 70%;
    border: none;
    border-radius: 0;
    font-size: 15px;
    background: #212529b8;
}
.profile-img .file input {
    position: absolute;
    opacity: 0;
    right: 0;
    top: 0;
}
.fotoFile {
  position: absolute;
  opacity: 0;
  right: 0;
  top: 0;
}
.profile-head h5{
    color: #333;
}
.profile-head h6{
    color: #622D7A;
}
.profile-edit-btn{
    border: none;
    border-radius: 1.5rem;
    width: 70%;
    padding: 2%;
    font-weight: 600;
    color: #6c757d;
    cursor: pointer;
}
.proile-text{
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 11px;
}
.table td{
  font-size: 11px;
  letter-spacing: 1px;
  font-size: 11px;
}
.proile-rating{
    font-size: 16px;
    color: #818182;
    letter-spacing: 2px;
}
.proile-rating span{
    color: #622D7A;
    font-size: 15px;
    font-weight: 600;
}
.profile-head .nav-tabs{
    margin-bottom:5%;
}
.profile-head .nav-tabs .nav-link{
    font-weight:600;
    border: none;
}
.profile-head .nav-tabs .nav-link.active{
    border: none;
    border-bottom:2px solid #622D7A;
}
.profile-work{
    padding: 14%;
    margin-top: -15%;
}
.profile-work p{
    font-size: 16px;
    color: #818182;
    font-weight: 600;
    margin-top: 10%;
}
.profile-work a{
    text-decoration: none;
    color: #666;
    font-weight: 600;
    font-size: 12px;
}
.profile-work ul{
    list-style: none;
}
.profile-tab label{
    font-weight: 600;
}
.profile-tab p{
    font-weight: 600;
    color: #622D7A;
}
.text-primary {color: #622D7A !important;}
.form-control{font-size: 12px;}
.shadow-textarea textarea.form-control::-webkit-input-placeholder {
    font-weight: 300;
}
.shadow-textarea textarea.form-control::-moz-placeholder {
    font-weight: 300;
}
.shadow-textarea textarea.form-control:-ms-input-placeholder {
    font-weight: 300;
}
.shadow-textarea textarea.form-control::-ms-input-placeholder {
    font-weight: 300;
}
.shadow-textarea textarea.form-control::placeholder {
    font-weight: 300;
}
.shadow-textarea textarea.form-control {
    padding-left: 0.8rem;
}
form{
  margin-top: 10px;
}
.btn-comentar { color: #74B643}
.btn-outline-success {
  color: #74B643;
  border-color: #74B643;
}
.btn-outline-success:hover {
  background-color: #74B643;
}
.btn-success{
  background-color: #74B643;
  border-color: #74B643;
  color: #FFF;
}
.btn-warning{
  background-color: #622D7A;
  border-color: #622D7A;
  color: #FFF !important;
}
.btn-success:hover{
  background-color: #282c34;
  border-color: #282c34;
}
.btn-warning:hover{
  background-color: #282c34;
  border-color: #282c34;
}
/* 282c34 */
.btn {margin: 10px 10px 10px 0; border-radius: 1.25rem; padding: 1px 7px 2px; font-size: .8rem;}
/* Individual item */
.bm-item {
  display: inline-block;
  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  /* color: #d1d1d1; */
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
  color: white;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #74B643;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: white;
  /* padding: 2.5em 1.5em 0; */
  padding: 1.0em 0;
  font-size: 1.15em;
  text-align: left;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
.modal-iframe {
  width: 700px;
}

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
img{ max-width: 100% }
.success { color: #28a745; }
.danger { color: #dc3545; }
.warning { color: #ffc107; }
.card-title { letter-spacing: 2px; text-transform: uppercase; text-align: center; font-size: 16px;
color: #818182;
margin-top: 10px; }
.card-subtitle { letter-spacing: 2px; text-align:center; }
.containerMsg{max-width:1170px; margin:auto;}
.container{width: auto; max-width: 850px; border-radius: 40px !important;}
.inbox_people {
  background: #f8f8f8 none repeat scroll 0 0;
  float: left;
  overflow: hidden;
  width: 40%; border-right:1px solid #c4c4c4;
}
.inbox_msg {
  border: 1px solid #c4c4c4;
  clear: both;
  overflow: hidden;
}
.top_spac{ margin: 20px 0 0;}


.recent_heading {float: left; width:40%;}
.srch_bar {
  display: inline-block;
  text-align: right;
  width: 60%;
}
.headind_srch{ padding:10px 29px 10px 20px; overflow:hidden; border-bottom:1px solid #c4c4c4;}

.recent_heading h4 {
  color: #05728f;
  font-size: 21px;
  margin: auto;
}
.srch_bar input{ border:1px solid #cdcdcd; border-width:0 0 1px 0; width:80%; padding:2px 0 4px 6px; background:none;}
.srch_bar .input-group-addon button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  padding: 0;
  color: #707070;
  font-size: 18px;
}
.srch_bar .input-group-addon { margin: 0 0 0 -27px;}

.chat_ib h5{ font-size:15px; color:#464646; margin:0 0 8px 0;}
.chat_ib h5 span{ font-size:13px; float:right;}
.chat_ib p{ font-size:14px; color:#989898; margin:auto}
.chat_img {
  float: left;
  width: 11%;
}
.chat_ib {
  float: left;
  padding: 0 0 0 15px;
  width: 88%;
}

.chat_people{ overflow:hidden; clear:both;}
.chat_list {
  border-bottom: 1px solid #c4c4c4;
  margin: 0;
  padding: 18px 16px 10px;
}
.inbox_chat { height: 550px; overflow-y: scroll;}

.active_chat{ background:#ebebeb;}

.incoming_msg_img {
  display: inline-block;
  width: 6%;
}
.received_msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 92%;
 }
 .received_withd_msg p {
  background: #ebebeb none repeat scroll 0 0;
  border-radius: 3px;
  color: #646464;
  font-size: 14px;
  margin: 0;
  padding: 5px 10px 5px 12px;
  width: 100%;
}
.time_date {
  color: #747474;
  display: block;
  font-size: 12px;
  margin: 8px 0 0;
}
.received_withd_msg { width: 57%;}
.mesgs {
  float: left;
  padding: 30px 15px 0 25px;
  width: 60%;
}

 .sent_msg p {
  background: #05728f none repeat scroll 0 0;
  border-radius: 3px;
  font-size: 14px;
  margin: 0; color:#fff;
  padding: 5px 10px 5px 12px;
  width:100%;
}
.outgoing_msg{ overflow:hidden; margin:26px 0 26px;}
.sent_msg {
  float: right;
  width: 46%;
}
.input_msg_write input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: #4c4c4c;
  font-size: 15px;
  min-height: 48px;
  width: 100%;
}

.type_msg {border-top: 1px solid #c4c4c4;position: relative;}
.msg_send_btn {
  background: #05728f none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  height: 33px;
  position: absolute;
  right: 0;
  top: 11px;
  width: 33px;
}
.messaging { padding: 0 0 50px 0;}
.msg_history {
  height: 516px;
  overflow-y: auto;
}
.col-form-label {
  text-align: left;
}
.avatar {
    position: relative;
}

.avatar img {
    border-radius: 50%;
}

.avatar .status {
    position: absolute;
    right: 0;
    top: 0;
    width: 12px;
    height: 12px;
    line-height: 12px;
    border-radius: 50%;
    border: solid 2px #ffffff;
}

.dropdown.avatar .status {
    right: 14px;
}
.caretl {
    color:#363838;
    font-size: 14px;
    line-height: 38px;
    padding: 0 15px;
}


.avatar .green {
    background-color: #80d3ab;
}

.avatar .red {
    background-color: #f27777;
}

.avatar .yellow {
    background-color: #ecd346;
}
/*--- Post --*/
.post {
    background-color: #ffffff;
    margin-bottom: 20px;
}
.post .wrap-ut {
    width: 85%;
}
.post .userinfo {
    width: 15%;
    padding: 20px 0 15px 15px;
}
.post .posttext {
    width: 85%;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 15px;

    color: #989c9e;
    font-size: 14px;
    line-height: 25px;
}
.post .postinfo {
    width: 15%;
    border-left: solid 1px #f1f1f1;


}
.post .avatar {
    width: 37px;
    margin-left: 5px;
}
.post .icons {
    width: 48px;
    border-top: solid 1px #f1f1f1;
    margin-top: 12px;
    padding-top: 7px;
}
.post h2 {
    color: #363838;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.post .comments {
    border-bottom: solid 1px #f1f1f1;
    padding: 18px 0 25px 0;
    text-align: center;
}
.post .comments .commentbg {
    background-color: #bdc3c7;
    border-radius: 2px;
    display: inline-block;
    padding: 12px 17px;
    color: #ffffff;
    font-size: 14px;
    position: relative;
}
.post .comments .commentbg .mark {
    width: 11px;
    height: 11px;
    background-color: #bdc3c7;
    position: absolute;
    bottom: 0;
    left: 43%;
    margin-bottom: -5px;
    transform:rotate(45deg);
    -ms-transform:rotate(45deg); /* IE 9 */
    -webkit-transform:rotate(45deg); /* Opera, Chrome, and Safari */
}
.post .views {
    border-bottom: solid 1px #f1f1f1;
    color:#9da6aa;
    font-size: 12px;
    text-align: center;
    line-height: 29px;
}
.post .views i {
    font-size: 14px;
}
.post .time {
    color:#9da6aa;
    font-size: 12px;
    text-align: center;
    line-height: 29px;
}
.post .time i, .form-check-label {
    font-size: 14px;
}
.fadeOut{
     opacity:0;
     /* transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s; */
     display: none;
}
.fadeIn{
     opacity:1;
     -webkit-transition: opacity 0.5s 0.5s;
     transition: opacity 0.5s 0.5s;
}

.login-block{
    min-height: 100vh;
    background: #DE6262;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #622D7A, #74B643);  /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-gradient(linear, left top, left bottom, from(#622D7A), to(#74B643));
    background: -webkit-linear-gradient(top, #622D7A, #74B643);
    background: linear-gradient(to bottom, #622D7A, #74B643); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    float:left;
    width:100%;
    padding : 50px 0;
}
.container{background:#fff; border-radius: 10px; box-shadow:15px 20px 0px rgba(0,0,0,0.1);}
.carousel-item{border-radius:0 10px 10px 0; width: 100%;
height: 450px;}
.carousel-caption{text-align:left; left:5%;}
.login-sec{padding: 50px 30px; position:relative;}
.login-sec .copy-text{position:absolute; width:80%; bottom:20px; font-size:13px; text-align:center;}
.login-sec .copy-text i{color:#FEB58A;}
.login-sec .copy-text a{color:#E36262;}
.login-sec h2{margin-bottom:30px; font-weight:800; font-size:30px; color: #DE6262;}
.login-sec h2:after{content:" "; width:100px; height:5px; background:#FEB58A; display:block; margin-top:20px; border-radius:3px; margin-left:auto;margin-right:auto}
.btn-login{background: #DE6262; color:#fff; font-weight:600;}
.banner-text{width:70%; position:absolute; bottom:40px; padding-left:20px;}
.banner-text h2{color:#fff; font-weight:600;}
.banner-text h2:after{content:" "; width:100px; height:5px; background:#FFF; display:block; margin-top:20px; border-radius:3px;}
.banner-text p{color:#fff;}

